
import {Vue, Component, Prop} from 'vue-property-decorator';
import {dispAuthFetch} from "@/store/mlm/actions";

@Component({})
export default class MyPromoCodes extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  public promoCodeList = []
  public isModalClosed: boolean = false;

  public async mounted() {
    await this.updateMyPromoCodes()
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal");
  }

  public async updateMyPromoCodes() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/dexnet_integration/dexid-promocodes/",
      init: {
        method: "POST",
      },
    });

    if (invoiceResp.status === 200) {
      this.promoCodeList = await invoiceResp.json()
    } else {
      this.$toast.error("Failed to create invoice, please try again later");
    }
  }

  public async createPrivateKey() {

  }

  public async createPublicKey() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/dexnet_integration/create-dexid-promocode/",
      init: {
        method: "POST",
        body: {},
      },
    });

    if (invoiceResp && invoiceResp.status === 200) {
      this.$toast.success(this.$t('Promo code activated successfully').toString());
      await this.updateMyPromoCodes()
    } else if (invoiceResp && invoiceResp.status === 400) {
      this.$toast.error("Check the correctness of filling");
    }
  }
}
